import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { AuthContainer } from './app/auth/AuthContainer'
import { Routes } from './app/Router'

const App = () => (
  <BrowserRouter>
    <AuthContainer>
      <Routes />
    </AuthContainer>
  </BrowserRouter>
)

export default App
