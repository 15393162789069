import React from 'react'
import {
  AppBar, Toolbar, Grid, MenuItem, Drawer, List, ListItem, ListItemIcon,
  ListItemText, Typography, Divider, Select, Collapse, Tooltip
} from '@material-ui/core'
import {
  Home as HomeIcon, LibraryBooks, AccountCircle, DeviceHub
} from '@material-ui/icons'
import {
  Route, Switch, Link, useLocation
} from 'react-router-dom'
import { useService } from '../../common/ServiceContext'
import { Home } from './Home'
import { Documentation } from './Documentation'
import { Plans } from './Plans'
import { useAuth } from '../auth/AuthContext'
import { Account } from './Account'
import { SubscriptionSuccess } from './SubscriptionSuccess'
import { Webhooks } from './Webhooks'

export const drawerWidth = '240px'

export const Dashboard = () => {
  const { authService } = useService()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const open = !!pathname.match('/documentation')
  return (
    <>
      <AppBar
        style={{
          boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.14)',
          backgroundColor: 'white'
        }}
        position="static"
      >
        <Toolbar>
          <Grid direction="row-reverse" container>
            <Select variant="outlined" value={1}>
              <MenuItem value={1}>My Account</MenuItem>
              <MenuItem onClick={() => authService.logout()}>Logout</MenuItem>
            </Select>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent">
        <div style={{
          width: '100%', justifyContent: 'center', height: 64, display: 'flex', alignItems: 'center'
        }}
        >
          <Typography variant="h6">Disaster SAFE</Typography>
        </div>
        <Divider style={{ backgroundColor: 'white', marginLeft: 20, marginRight: 20 }} />
        <List>
          <ListItem button component={Link} to="/home">
            <ListItemIcon><HomeIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/documentation">
            <ListItemIcon><LibraryBooks style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Documentation" />
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
              <ListItem button component={Link} to="/documentation#/paths/~1events/get">
                <ListItemText inset style={{ paddingLeft: 80 }} primary="Events" />
              </ListItem>
              <ListItem button component={Link} to="/documentation#/paths/~1countries/get">
                <ListItemText inset style={{ paddingLeft: 80 }} primary="Countries" />
              </ListItem>
              <ListItem button component={Link} to="/documentation#/paths/~1webhooks/post">
                <ListItemText inset style={{ paddingLeft: 80 }} primary="Webhooks" />
              </ListItem>
            </List>
          </Collapse>
          {user.subscriptionStatus === 'active'
            ? (
              <ListItem button component={Link} to="/webhooks">
                <ListItemIcon><DeviceHub style={{ color: 'white' }} /></ListItemIcon>
                <ListItemText primary="Webhooks" />
              </ListItem>
            )
            : (
              <Tooltip title="Please upgrade account to use webhooks" style={{ fontSize: 20 }} placement="bottom">
                <ListItem button>
                  <ListItemIcon><DeviceHub style={{ color: 'grey' }} /></ListItemIcon>
                  <ListItemText style={{ color: 'grey' }} primary="Webhooks" />
                </ListItem>
              </Tooltip>
            )}
          {user.subscriptionStatus === 'active'
            ? (
              <ListItem button component={Link} to="/account">
                <ListItemIcon><AccountCircle style={{ color: 'white' }} /></ListItemIcon>
                <ListItemText primary="Account" />
              </ListItem>
            )
            : (
              <ListItem button component={Link} to="/plans">
                <ListItemIcon><AccountCircle style={{ color: 'white' }} /></ListItemIcon>
                <ListItemText primary="Upgrade" />
              </ListItem>
            )}
        </List>
      </Drawer>
      <div style={{ marginLeft: drawerWidth, padding: 40 }}>
        <Switch>
          <Route path="/webhooks">
            {user.subscriptionStatus === 'active' && <Webhooks />}
          </Route>
          <Route path="/subscription-success" component={SubscriptionSuccess} />
          <Route path="/documentation" component={Documentation} />
          <Route path="/account">
            {user.subscriptionStatus === 'active' && <Account />}
          </Route>
          <Route path="/plans">
            <Plans subscriptionValid={user.subscriptionStatus === 'active'} />
          </Route>
          <Route path="/home" component={Home} />
          <Route path="*" component={Home} />
        </Switch>
      </div>
    </>
  )
}
