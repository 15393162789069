import React from 'react'
import { ServiceContext } from './ServiceContext'
import { firebaseAuth, fireStore } from '../capabilities/firebase'
import { authService } from '../services/auth'
import { dciClient } from '../capabilities/dciClient'
import { DCIService } from '../services/dci'

dciClient.interceptors.request.use((config) => {
  if (firebaseAuth.currentUser) {
    return firebaseAuth.currentUser.getIdToken().then(
      (token) => ({
        ...config,
        headers: {
          ...config.headers,
          Authorization: token
        }
      })
    )
  }
  return Promise.reject(new Error('Not authenticated, please log in before performing this action.'))
})

const services = {
  firebaseAuth,
  authService: authService({ firebaseAuth, dciClient }),
  DCIService: DCIService({ dciClient, fireStore, firebaseAuth }),
  dciClient
}

export type Services = typeof services

export const ServiceContainer = ({ children }: { children: React.ReactNode }) => (
  <ServiceContext.Provider value={services}>
    {children}
  </ServiceContext.Provider>
)
