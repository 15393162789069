export const firebaseConfig = {
  apiKey: 'AIzaSyDSk0j3RuE8s8vxAbtP9qos_NdViPCQolQ',
  authDomain: 'rabbit-island.firebaseapp.com',
  databaseURL: 'https://rabbit-island.firebaseio.com',
  projectId: 'rabbit-island',
  storageBucket: 'rabbit-island.appspot.com',
  messagingSenderId: '716458710478',
  appId: '1:716458710478:web:364eea45efcdc248d9c79f',
  measurementId: 'G-GWWHK1B8HQ'
}
