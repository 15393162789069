import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { PhonelinkSetup, Public, Redeem } from '@material-ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'

export const About = () => (
  <List>
    <ListItem>
      <ListItemAvatar><Public /></ListItemAvatar>
      <ListItemText
        primary="A modern realtime API for natural disaster events"
        secondary="Query and retreive up to date global natural disaster data or get notified in realtime via webhooks, retrieve emergency contact numbers from countries and locations across the world."
      />
    </ListItem>
    <ListItem>
      <ListItemAvatar><PhonelinkSetup /></ListItemAvatar>
      <ListItemText
        primary="Powering the Disaster Check In iOS App"
        secondary="This API powers the free iOS mobile app Disaster Check In."
      />
    </ListItem>
    <ListItem>
      <ListItemAvatar><Redeem /></ListItemAvatar>
      <ListItemText
        primary="Start Free now"
        secondary="We offer a limited free forever tier for trying out the API and for non-profits. For commercial usage we offer a premium subscription with unlimited API access, support and other exclusive features."
      />
    </ListItem>
    <div style={{
      textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      <Button variant="contained" color="primary">
        <Link to="/signup">
          Get Started
        </Link>
      </Button>
      <p><Link to="/login" style={{ color: 'grey' }}>Already have an account? Login Here</Link></p>
    </div>
  </List>
)
