import React, { useState, useEffect } from 'react'
import { string } from 'yup'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  Typography, TextField, IconButton, Button, Paper,
  List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
  Divider, Collapse, Dialog, DialogTitle, DialogContent, DialogContentText,
  Box, CircularProgress, DialogActions
} from '@material-ui/core'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'
import { useService } from '../../common/ServiceContext'
import { Loader } from '../../common/components/Loader'
import { WebhookResponses } from './WebhookResponses'
import { Link } from 'react-router-dom'

const WebhookRow = ({ a, del }: any) => {
  const [loading, setLoading] = useState(true)
  const { DCIService } = useService()
  const [data, setData] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [showSecret, setShowSecret] = useState(false)
  const [delOpen, setDelOpen] = useState(false)
  useEffect(() => {
    if (copied) {
      const b = setTimeout(() => {
        setCopied(false)
        setShowSecret(false)
      }, 2000)
      return () => { clearTimeout(b) }
    }
    return () => {}
  }, [copied])
  useEffect(() => {
    DCIService.getWebhookResponses(a.url)
      .then((res) => {
        setLoading(false)
        setData(res.data)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])
  return (
    <>
      <Dialog
        open={delOpen}
        onClose={() => setDelOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: 20 }}
      >
        <DialogTitle id="alert-dialog-title">Are you sure you wish to delete this webhook subscription?</DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <DialogContentText id="alert-dialog-description">
            You will stop receiving event webhooks at
            {' '}
            {a.url}
            {' '}
            if you delete this webhook subscription.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelOpen(false)} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={() => del(a.url)} color="secondary" autoFocus variant="contained">
            Delete webhook subscription
          </Button>
        </DialogActions>
      </Dialog>
      <ListItem>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          <ListItemText style={{ width: 300, overflow: 'scroll' }} secondary="Active">{a.url}</ListItemText>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 20, marginLeft: 20 }}
            onClick={() => {
              setShowSecret(true)
            }}
          >
            Reveal
          </Button>
          <CopyToClipboard
            onCopy={() => {
              setCopied(true)
            }}
            text={a.secret}
          >
            <div style={showSecret ? {
              cursor: 'pointer', width: 400, textAlign: 'center'
            } : {
              filter: 'blur(4px)', width: 400, textAlign: 'center', pointerEvents: 'none'
            }}
            >
              {copied ? 'Copied!' : a.secret || 'wh_xxxxxxxxxxxxxxxxxxxxxxxx'}
            </div>
          </CopyToClipboard>
        </div>
        <ListItemSecondaryAction>
          <Button
            onClick={() => setDelOpen(true)}
            variant="contained"
            color="secondary"
          >
            Delete
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <WebhookResponses data={data} />
          <Divider />
        </ListItem>
      </Collapse>
    </>
  )
}

export const Webhooks = () => {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [webhooks, setWebhooks] = useState<any[]>([])
  const { DCIService } = useService()
  const load = () => DCIService.getWebhooks()
    .then((events) => {
      setWebhooks(events.data)
      setLoading(false)
    })
    .catch((e) => {
      setLoading(false)
    })
  const submit = () => {
    if (url.startsWith('https')) {
      setLoading(true)
      return string().url().validate(url)
        .then(() => DCIService.createWebhook(url))
        .then(load)
        .then(() => {
          setError('')
          setUrl('')
          setLoading(false)
        })
        .catch((e) => {
          setError('Please specify a valid url')
          setLoading(false)
        })
    }
    setError('Please specify a valid https url')
  }
  const del = (webhookUrl: string) => DCIService.deleteWebhook(webhookUrl)
    .then(load)
  useEffect(() => {
    load()
  }, [])
  if (loading) {
    return (
      <Loader />
    )
  }
  return (
    <Paper style={{ padding: 40 }}>
      <Typography variant="h6">
        Webhook subscriptions
      </Typography>
      <Typography>
        You are allowed to register 2 webhook subscription endpoints, which must be using https.
        We will push you natural disaster events as we receive them.
        Check out the webhooks&nbsp;
        <Link style={{ color: 'black', textDecoration: 'underline' }} to="/documentation#/paths/~1webhooks/post">documentation</Link>
        &nbsp;to learn more about how to start using webhooks.
      </Typography>
      <List>
        {webhooks.map((a) => <WebhookRow a={a} del={del} />)}
      </List>
      <div style={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 50
      }}
      >
        <TextField
          value={url}
          placeholder="https://myapi.com"
          onChange={(e) => setUrl(e.target.value)}
          disabled={webhooks.length > 1}
          style={{ width: 350 }}
          error={!!error}
          helperText={error}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              submit()
            }
          }}
        />
        <Button
          onClick={() => submit()}
          disabled={webhooks.length > 1}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </div>
    </Paper>
  )
}
