import React, { useEffect } from 'react'
import { CheckCircle } from '@material-ui/icons'
import { Typography, LinearProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useService } from '../../common/ServiceContext'
import { useAuth } from '../auth/AuthContext'

export const SubscriptionSuccess = () => {
  const history = useHistory()
  const { authService } = useService()
  const { setAuthCtx } = useAuth()

  useEffect(() => {
    let i: number = 0
    const t = setInterval(() => {
      authService.getUser()
        .then((auth) => {
          i += 1
          if (auth.data.data.subscriptionStatus === 'active') {
            setAuthCtx({
              ...auth.data.data
            })
            history.replace('/home')
          }
        })
        .catch(() => { })
    }, 2000)
    return () => { clearInterval(t) }
  }, [])
  return (
    <div style={{
      backgroundColor: 'rgb(233, 240, 255)',
      zIndex: 2000,
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      textAlign: 'center'
    }}
    >
      <LinearProgress />
      <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <CheckCircle style={{
          height: 200, width: 200, color: 'rgb(94 188 255)', marginBottom: 50
        }}
        />
        <Typography variant="h5">Success, please wait whilst your account is upgraded</Typography>
      </div>
    </div>
  )
}
