import firebase from 'firebase'
import { AxiosInstance } from 'axios'

export const authService = ({ firebaseAuth, dciClient }
  : { firebaseAuth: firebase.auth.Auth, dciClient: AxiosInstance }) => ({
  getUser: async () => dciClient.get('/api/v1/user'),
  login: async (email: string, password: string) => firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then(() => dciClient.put('/api/v1/user', { email })),
  signUp: async (email: string, password: string) => firebaseAuth
    .createUserWithEmailAndPassword(email, password)
    .then(() => dciClient.put('/api/v1/user', { email })),
  logout: () => firebaseAuth.signOut().then(() => {
    const w = window as unknown as any
    w.fcWidget.user.clear()
  }),
  forgotPassword: (email: string) => firebaseAuth.sendPasswordResetEmail(email)
})

export type AuthService = typeof authService
