import React, { useState, useEffect } from 'react'
import {
  Paper, Typography, List, ListItem, ListItemText, Divider, Grid, Button, ListItemIcon, CircularProgress, Fade
} from '@material-ui/core'
import moment from 'moment'
import {
  CheckBoxOutlineBlank, ArrowForward, CheckBoxOutlined, ShowChart, CardGiftcard
} from '@material-ui/icons'
import {
  ScatterChart, CartesianGrid, XAxis, YAxis, Tooltip, Scatter,
  RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis
} from 'recharts'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useService } from '../../common/ServiceContext'
import { eventTypeToName, DashboardState } from '../../services/dci'
import { useAuth } from '../auth/AuthContext'

export const Home = () => {
  const { DCIService } = useService()
  const [events, setEvents] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)
  const [reveal, setReveal] = useState(false)
  const [dashboardState, setDashboardState] = useState<DashboardState>({})
  useEffect(() => {
    if (copied) {
      const a = setTimeout(() => {
        setCopied(false)
        setReveal(false)
      }, 2000)
      return () => { clearTimeout(a) }
    }
    return () => {}
  }, [copied])
  const { user } = useAuth()
  useEffect(() => {
    Promise.all([
      DCIService.getEvents(),
      DCIService.getDashboardState()
    ])
      .then(([events, dashState]) => {
        setDashboardState(dashState)
        setEvents(events.data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])
  const earthquakes = events.filter((a) => a.type === 'EQ').sort((a, b) => {
    if (moment(a).isAfter(moment(b))) {
      return b
    }
    return a
  }).map((a) => ({
    ...a,
    createdAt: moment(a.createdAt).fromNow()
  }))
  const aggregateEventsObj = events.map((a) => ({ ...a, type: eventTypeToName(a.type) })).reduce((acc, item) => {
    acc[item.type] = ((acc[item.type]) || 0) + 1
    return acc
  }, {})
  const aggregateEvents = Object.keys(aggregateEventsObj).map((key) => ({ count: aggregateEventsObj[key], type: key }))
  return (
    <Grid spacing={5}>
      <Grid item xs={12} style={{ paddingBottom: 40 }}>
        <Paper style={{ padding: 40 }}>
          <Typography variant="h6">Get started</Typography>
          <List>
            <ListItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {dashboardState.keyRevealed ? <CheckBoxOutlined style={{ paddingRight: 5, color: 'green' }} /> : <CheckBoxOutlineBlank style={{ paddingRight: 5 }} />}
                Get your API key - do not share this with anyone. Only use this in a secure environment such as a web server.
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 20, marginLeft: 20 }}
                  onClick={() => {
                    setReveal(true)
                  }}
                >
                  Reveal
                </Button>
                <CopyToClipboard
                  onCopy={() => {
                    setCopied(true)
                    DCIService.setKeyRevealed()
                      .then(() => {
                        setDashboardState((state) => ({ ...state, keyRevealed: true }))
                      })
                  }}
                  text={user.apiSecretKey}
                >
                  <div style={reveal ? { cursor: 'pointer', width: 400, textAlign: 'center' } : { filter: 'blur(4px)', width: 400, textAlign: 'center', pointerEvents: 'none' }}>
                    {copied ? 'Copied!' : user.apiSecretKey || 'sk_xxxxxxxxxxxxxxxxxxxxxxxx'}
                  </div>
                </CopyToClipboard>
              </div>
            </ListItem>
            <Divider />
            <ListItem button component={Link} to="/documentation" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {dashboardState.documentationSeen ? <CheckBoxOutlined style={{ paddingRight: 5, color: 'green' }} /> : <CheckBoxOutlineBlank style={{ paddingRight: 5 }} />}
                Check out our documentation
              </div>
              <ArrowForward />
            </ListItem>
            <Divider />
            <ListItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Fade in={dashboardState.documentationSeen && dashboardState.keyRevealed}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CardGiftcard style={{ paddingRight: 5 }} />
                  You&apos;re ready to begin using Disaster SAFE
                </div>
              </Fade>
            </ListItem>
          </List>
        </Paper>
      </Grid>
      <Grid container spacing={5}>
        {
          loading ? (
            <div style={{
              display: 'flex', width: '100%', justifyContent: 'center', padding: 50
            }}
            >
              <CircularProgress />
            </div>
          )
            : (
              <>
                <Grid item xs={6}>
                  <Paper style={{ padding: 40 }}>
                    <Typography variant="h6">Latest Events</Typography>
                    <List>
                      {events.slice(0, 5).map((event) => (
                        <ListItem>
                          <ListItemIcon>
                            <ShowChart />
                          </ListItemIcon>
                          <ListItemText
                            primary={event.title}
                            secondary={moment(event.createdAt).fromNow()}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grid>
                <Grid item xs={6} spacing={5}>
                  <Grid item xs={12}>
                    <Paper style={{ padding: 40 }}>
                      <Typography variant="h6">Recent Earthquakes</Typography>
                      <ScatterChart
                        width={400}
                        height={250}
                        margin={{
                          top: 20, right: 20, bottom: 10, left: 10
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="createdAt" angle={-45} height={50} tick={{ fontSize: 10 }} textAnchor="end" name="date" reversed />
                        <YAxis dataKey="magnitudeValue" name="magnitude" unit="M" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Scatter name="Earthquakes" data={earthquakes} fill="#8884d8" />
                      </ScatterChart>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper style={{ padding: 40, marginTop: 40 }}>
                      <Typography variant="h6">Recent Event Distribution</Typography>
                      <RadarChart cx={230} cy={130} outerRadius={100} width={400} height={200} data={aggregateEvents}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="type" />
                        <PolarRadiusAxis />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Radar name="Recent Events" dataKey="count" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                      </RadarChart>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            )
}
      </Grid>
    </Grid>
  )
}
