import { AxiosInstance } from 'axios'
import { firestore, auth } from 'firebase'

export const eventTypeToName = (type: string) => {
  if (type === 'EQ') return 'Earthquake'
  if (type === 'TC') return 'Tropical cyclone'
  if (type === 'FL') return 'Flood'
  if (type === 'VO') return 'Volcano'
  if (type === 'DR') return 'Drought'
  return ''
}

export type DashboardState = {
  keyRevealed?: true
  documentationSeen?: true
}

export const DCIService = ({ dciClient, fireStore, firebaseAuth }
  : { dciClient: AxiosInstance, fireStore: firestore.Firestore, firebaseAuth: auth.Auth }) => ({
  getWebhooks: () => dciClient.get('/v1/webhooks')
    .then((res) => res.data),
  createWebhook: (url: string) => dciClient.post('/v1/webhooks', { url })
    .then((res) => res.data),
  deleteWebhook: (url: string) => dciClient.post('/v1/webhooks/cancel', { url })
    .then((res) => res.data),
  getEvents: () => dciClient.get('/api/v1/events')
    .then((res) => res.data),
  getWebhookResponses: (url: string) => dciClient.get('/v1/webhooks/responses', { params: { url } })
    .then((res) => res.data),
  getSubscription: () => dciClient.get('/api/v1/user/subscription')
    .then((res) => res.data),
  cancelSubscription: () => dciClient.delete('/api/v1/user/subscription')
    .then((res) => res.data),
  getDashboardState: (): Promise<DashboardState> => fireStore.collection('users').doc(firebaseAuth.currentUser?.uid).get()
    .then((doc) => {
      if (!doc.exists) return {}
      return doc.data() as DashboardState
    }),
  setKeyRevealed: () => fireStore.collection('users').doc(firebaseAuth.currentUser?.uid).set({
    keyRevealed: true
  }, {
    merge: true
  })
    .catch(() => {}),
  setDocsSeen: () => fireStore.collection('users').doc(firebaseAuth.currentUser?.uid).set({
    documentationSeen: true
  }, {
    merge: true
  })
    .catch(() => {})
})

export type DCIService = typeof DCIService
