import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import './index.css'
import App from './App'
import { ServiceContainer } from './common/ServiceContainer'
import { drawerWidth } from './app/dashboard/Dashboard'

ReactDOM.render(
  <React.StrictMode>
    <ServiceContainer>
      <ThemeProvider theme={createMuiTheme({
        overrides: {
          MuiDrawer: {
            paperAnchorDockedLeft: {
              width: drawerWidth,
              borderRight: 'none',
              boxShadow: '0px 21px 16px rgba(5, 0, 56, 0.08)',
              background: 'linear-gradient(#1846c7, #00135a)',
              color: 'white'
            }
          },
          MuiSelect: {
            root: {
              padding: '11.5px 14px'
            }
          }
          // MuiPaper: {
          //   root: {
          //     backgroundColor: 'rgba(235,235,245)'
          //   }
          // }
        },
        palette: {
          primary: {
            main: '#1846c7'
          }
        }
      })}
      >
        <App />
      </ThemeProvider>
    </ServiceContainer>
  </React.StrictMode>,
  document.getElementById('root')
)
