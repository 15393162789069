import { createContext, useContext } from 'react'
import { Services } from './ServiceContainer'

export const ServiceContext = createContext<Services | undefined>(undefined)

export const useService = () => {
  const ctx = useContext(ServiceContext)
  if (!ctx) { throw new Error(`${ServiceContext.displayName} context not found`) }
  return ctx
}
