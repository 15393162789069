import { Box, Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'
import { About } from './About'
import { ForgotPassword } from './ForgotPassword'
import { Login } from './Login'
import { SignUp } from './SignUp'

export const AuthPage = () => (
  <div style={{
    background: 'linear-gradient(#049bff,#04f)',
    position: 'relative',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden'
  }}
  >
    <div
      style={{
        position: 'absolute',
        width: '2550px',
        height: '2550px',
        top: '-1550px',
        transform: 'translateX(-4%)',
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '100%',
        overflow: 'hidden',
        left: '30%'
      }}
      className="background-animation"
    />
    <div style={{
      width: '100%',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row-reverse',
      top: 20,
      zIndex: 2
    }}
    >
      <Link to="/about" style={{ display: 'inline-block' }}>
        <Typography variant="subtitle1" style={{ marginRight: 20, padding: 20 }}><strong>About</strong></Typography>
      </Link>
      <a href="mailto:team@disastercheckin.app" rel="noopener">
        <Typography variant="subtitle1" style={{ marginRight: 20, padding: 20 }}><strong>Contact</strong></Typography>
      </a>
    </div>
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: 200,
        color: '#002eac'
      }}
      id="auth-grid"
    >
      <Grid
        container
        justify="center"
        direction="column"
        alignItems="center"
      >
        <Box
          id="auth-box"
          style={{
            background: 'rgba(255, 255, 255, 1)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h4" style={{ textAlign: 'center', marginBottom: 20 }}>
            Disaster
            <span className="text-bg-red">SAFE</span>
          </Typography>
          {/* <code style={{ fontSize: 30, margin: 10 }}>
            API
          </code> */}
          <Switch>
            <Route path="/about" component={About} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="*" component={Login} />
          </Switch>
        </Box>
      </Grid>
      <div style={{
        position: 'absolute', bottom: 2, right: 2, color: 'white'
      }}
      >
        <Typography style={{ fontSize: 14 }}>
          © Disaster SAFE&nbsp;
          {new Date().getFullYear()}
          &nbsp;&nbsp;
          {/* Rabbit island LTD */}
        </Typography>
      </div>
      <div style={{
        position: 'absolute', bottom: 2, left: 5, color: 'white'
      }}
      >
        <Typography style={{ fontSize: 14 }}>
          Rabbit Island LTD
        </Typography>
      </div>
    </Container>
  </div>
)
