import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, List, ListItem, Paper, Typography } from '@material-ui/core'
import { CheckBoxOutlined } from '@material-ui/icons'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'
import { useService } from '../../common/ServiceContext'
import { useAuth } from '../auth/AuthContext'

const stripeP = loadStripe('pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27')

// eslint-disable-next-line no-undef

export const Plans = ({ subscriptionValid }: { subscriptionValid: boolean }) => {
  const [error, setError] = useState('')
  const { firebaseAuth, DCIService, authService } = useService()
  const [downgradeDialogOpen, setDowngradeDialogOpen] = useState(false)
  const handleUpgrade = () => stripeP.then((stripe) => {
    if (stripe) {
      return stripe.redirectToCheckout({
        ...firebaseAuth.currentUser?.email && { customerEmail: firebaseAuth.currentUser?.email },
        lineItems: [{ price: 'price_1HQ5uhEjAiMLMtrKhHSYmkj1', quantity: 1 }],
        mode: 'subscription',
        successUrl: 'https://dashboard.disastercheckin.app/subscription-success',
        cancelUrl: 'https://dashboard.disastercheckin.app/subscription-cancelled'
      })
        .then((result: any) => {
          if (result.error) {
            setError(result.error.message)
          }
        })
    }
    return undefined
  })
  const [dgLoading, setDgLoading] = useState(false)
  const { setAuthCtx, user } = useAuth()
  const handleDowngrade = () => {
    setDgLoading(true)
    DCIService.cancelSubscription()
      .then((auth) => {
        setDgLoading(false)
        setAuthCtx({
          ...user,
          subscriptionStatus: 'cancelled'
        })
        setDowngradeDialogOpen(false)
      })
      .catch(() => {
        setDgLoading(false)
      })
  }
  return (
    <>
      <Dialog
        open={downgradeDialogOpen}
        onClose={() => setDowngradeDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: 20 }}
      >
        <DialogTitle id="alert-dialog-title">Are you sure you wish to cancel your Professional subscription?</DialogTitle>
        <DialogContent style={{ margin: 20 }}>
          <DialogContentText id="alert-dialog-description">
            You will no longer be invoiced for your subscription to Disaster SAFE. Your Professional subscription will end at the end of the period even if you cancel before then.
            You will be subject to the hobby plans limitations including API rate limiting after the period ends.
          </DialogContentText>
        </DialogContent>
        {dgLoading ? <Box style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box> : (
          <DialogActions>
            <Button onClick={() => setDowngradeDialogOpen(false)} color="primary" variant="contained">
              No, don't cancel my subscription
            </Button>
            <Button onClick={handleDowngrade} color="secondary" autoFocus variant="contained">
              Yes, cancel my subscription
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Grid container spacing={5}>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
          <Typography variant="h3">Disaster SAFE</Typography>
          <Typography variant="h6">Disaster SAFE is a product of Rabbit Island LTD</Typography>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: 40, textAlign: 'center' }}>
            <Typography variant="h6"><strong>Hobby</strong></Typography>
            <Typography>Free</Typography>
            <List>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5 }} />
                Free
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5 }} />
                100 API calls per day
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5 }} />
                Access to documentation
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5 }} />
                Use in open source projects with accreditation
              </ListItem>
              <div style={{ marginTop: 20 }}>
                {!subscriptionValid
                  ? (
                    <Button variant="contained" color="primary" disabled>
                      Your plan
                    </Button>
                  )
                  : (
                    <Button onClick={() => setDowngradeDialogOpen(true)} variant="contained" color="primary">
                      Select plan
                    </Button>
                  )}
              </div>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{ padding: 40, textAlign: 'center' }}>
            <Typography variant="h6"><strong>Professional</strong></Typography>
            <Typography>£39.99 per month</Typography>
            <List>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Unlimited API access*
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Access to documentation and support
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Integration help and reccomendation
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Early access to new API features
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Use in commercial projects
              </ListItem>
              <ListItem>
                <CheckBoxOutlined style={{ paddingRight: 5, color: 'rgb(219 222 0)' }} />
                Help support Disaster Check In**
              </ListItem>
              <div style={{ margin: 20 }}>
                {subscriptionValid
                  ? (
                    <Button variant="contained" color="primary" disabled>
                      Your plan
                    </Button>
                  )
                  : (
                    <Button onClick={handleUpgrade} variant="contained" color="primary">
                      Upgrade
                    </Button>
                  )}
              </div>
              {error && <div>{error}</div>}
            </List>
            <p style={{ fontSize: 10 }}>* The number of API calls is unlimited subject to fair and considerate usage, please do not aggresively poll the API more than once per minute thank you</p>
            <p style={{ fontSize: 10 }}>** We rely on Premium accounts to maintain the Disaster SAFE which powers the Disaster SAFE APP that provides disaster notifications for free to users all around the world, by upgrading you are helping us maintain this service</p>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
