import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import { useService } from '../../common/ServiceContext'
import { AuthPage } from './AuthPage'
import { AuthContext } from './AuthContext'

export const AuthContainer = ({ children }: { children: React.ReactNode }) => {
  const { firebaseAuth, authService } = useService()
  const [user, authLoading, error] = useAuthState(firebaseAuth)
  const [loading, setLoading] = useState(false)
  const [u, setU] = useState({})
  const value = { user: u, setAuthCtx: setU }
  useEffect(() => {
    if (user) {
      setLoading(true)
      authService.getUser()
        .then((auth) => {
          setLoading(false)
          setU({
            ...auth.data.data
          })
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [user])
  if (authLoading || loading) {
    return (
      <div style={{
        width: '100%',
        height: '100%',
        transform: 'translate(-25px, -25px)',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        display: 'flex'
      }}
      >
        <CircularProgress thickness={5} size={50} />
      </div>
    )
  }
  if (!user) {
    return <AuthContext.Provider value={value}><AuthPage /></AuthContext.Provider>
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
