import { createContext, useContext } from 'react'

export interface IUser {
  id?: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  imageUri: string
  createdAt?: Date
  updatedAt?: Date
  pushToken: string
  latlong?: [string, string]
  geo?: string
  _deletedAt?: Date
  shareLink?: string
  privacy: boolean
  notificationOptIn: boolean
  apiSecretKey: string
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  subscriptionPeriodEnd?: string
  subscriptionStatus?: string
}

export interface Auth {
  user: IUser,
  setAuthCtx: React.Dispatch<React.SetStateAction<IUser>>
}

export const AuthContext = createContext<Auth | {}>({})

export const useAuth = () => {
  const ctx = useContext(AuthContext)
  if (!ctx) { throw new Error(`${AuthContext.displayName} context not found`) }
  return ctx as Auth
}
