import React, { useState, useEffect } from 'react'
import { RedocStandalone } from 'redoc'
import { LinearProgress } from '@material-ui/core'
import { spec } from '../../asset/documentationSpec'
import { useService } from '../../common/ServiceContext'

export const Documentation = () => {
  const { DCIService } = useService()
  useEffect(() => {
    DCIService.setDocsSeen()
  }, [])
  const [loading, setLoading] = useState(true)
  return (
    <>
      {loading && <LinearProgress variant="indeterminate" />}
      <RedocStandalone
        spec={spec}
        options={{
          hideLoading: true,
          disableSearch: true,
          hideDownloadButton: true,
          theme: {
            spacing: {
              unit: 5,
              sectionHorizontal: ({ spacing }) => spacing.unit * 8,
              sectionVertical: ({ spacing }) => spacing.unit * 8
            },
            rightPanel: {
              width: '400px',
              backgroundColor: '#39466c'
            }
          }
        }}
        onLoaded={
          () => setLoading(false)
        }
      />
    </>
  )
}
