export const spec = {
  openapi: '3.0.0',
  servers: [
    {
      url: 'https://api.disastercheckin.app/v1',
      description: 'API url'
    }
  ],
  info: {
    title: 'Disaster SAFE',
    description: 'Welcome to the Disaster SAFE. You can retrieve natural disaster event data from this API. Responses are returned in JSON. Please include your API key in all requests in the authorization header.'
  },
  // tags: [
  //   {
  //     name: 'Events',
  //     description: 'The events services return Natural disaster data.'
  //   }
  // ],
  paths: {
    '/events': {
      parameters: [
        {
          name: 'Authorization',
          in: 'header',
          description: 'secret key',
          example: 'sk_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          required: true
        },
        {
          name: 'lat',
          in: 'query',
          description: 'Latitude',
          example: '52.2053'
        },
        {
          name: 'long',
          in: 'query',
          description: 'Longitude',
          example: '0.1218'
        }
      ],
      get: {
        summary: 'Fetch natural disaster events',
        description: 'Fetch natural disaster events.',
        responses: {
          200: {
            description: 'Successfully fetch natural disaster events',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          id: {
                            type: 'string'
                          },
                          title: {
                            type: 'string'
                          },
                          description: {
                            type: 'string'
                          },
                          url: {
                            type: 'string'
                          },
                          severity: {
                            type: 'string'
                          },
                          alertlevel: {
                            type: 'string'
                          },
                          alertscore: {
                            type: 'number'
                          },
                          latlong: {
                            type: 'array',
                            items: [{
                              type: 'string'
                            },
                            {
                              type: 'string'
                            }]
                          },
                          geo: {
                            type: 'string'
                          },
                          createdAt: {
                            type: 'date-time'
                          },
                          magnitudeUnit: {
                            type: 'string'
                          },
                          magnitudeValue: {
                            type: 'string'
                          },
                          type: {
                            type: 'string',
                            enum: ['EQ', 'TC', 'FL', 'VO', 'DR']
                          },
                          guid: {
                            type: 'string'
                          },
                          updatedAt: {
                            type: 'date-time',
                            nullable: true
                          },
                          _deletedAt: {
                            type: 'date-time',
                            nullable: true
                          },
                          landCountry: {
                            type: 'number',
                            nullable: true
                          }
                        }
                      }
                    },
                    object: {
                      type: 'string'
                    },
                    totalResults: {
                      type: 'number'
                    },
                    hasMore: {
                      type: 'boolean'
                    },
                    page: {
                      type: 'number'
                    }
                  }
                }
              }
            }
          },
          429: {
            description: 'Quota exceeded'
          },
          400: {
            status: 400,
            message: 'Bad request'
          }
        }
      }
    },
    '/countries': {
      parameters: [
        {
          name: 'Authorization',
          in: 'header',
          description: 'secret key',
          example: 'sk_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          required: true
        }
      ],
      get: {
        summary: 'Fetch country emergency contact numbers',
        description: 'Fetch country emergency contact numbers, this API may not be up to date with all real world emergency numbers, if any inconsistencies are noticed please let us know at team@disastercheckin.app, Thanks!',
        responses: {
          200: {
            description: 'Successfully fetch natural disaster events',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          gid: {
                            type: 'string'
                          },
                          name: {
                            type: 'string'
                          },
                          fireNumber: {
                            type: 'string',
                            nullable: true
                          },
                          emergencyNumber: {
                            type: 'string',
                            nullable: true
                          },
                          policeNumber: {
                            type: 'string',
                            nullable: true
                          },
                          ambulanceNumber: {
                            type: 'string',
                            nullable: true
                          }
                        }
                      }
                    },
                    object: {
                      type: 'string'
                    },
                    totalResults: {
                      type: 'number'
                    },
                    hasMore: {
                      type: 'boolean'
                    },
                    page: {
                      type: 'number'
                    }
                  }
                }
              }
            }
          },
          429: {
            description: 'Quota exceeded'
          },
          400: {
            status: 400,
            message: 'Bad request'
          }
        }
      }
    },
    '/countries/reverse-geocode': {
      parameters: [
        {
          name: 'Authorization',
          in: 'header',
          description: 'secret key',
          example: 'sk_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          required: true
        },
        {
          name: 'lat',
          in: 'query',
          description: 'Latitude',
          example: '52.2053',
          required: true
        },
        {
          name: 'long',
          in: 'query',
          description: 'Longitude',
          example: '0.1218',
          required: true
        }
      ],
      get: {
        summary: 'Fetch emergency numbers by location',
        description: 'This API lets you reverse geocode emergency numbers, emergency numbers are per country. Again this API may not be up to date with all real world emergency numbers, if any inconsistencies are noticed please let us know at team@disastercheckin.app, Thanks!',
        responses: {
          200: {
            description: 'Successfully fetch natural disaster events',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        gid: {
                          type: 'string'
                        },
                        name: {
                          type: 'string'
                        },
                        fireNumber: {
                          type: 'string',
                          nullable: true
                        },
                        emergencyNumber: {
                          type: 'string',
                          nullable: true
                        },
                        policeNumber: {
                          type: 'string',
                          nullable: true
                        },
                        ambulanceNumber: {
                          type: 'string',
                          nullable: true
                        }
                      }
                    },
                    object: {
                      type: 'string'
                    },
                    totalResults: {
                      type: 'number'
                    },
                    hasMore: {
                      type: 'boolean'
                    },
                    page: {
                      type: 'number'
                    }
                  }
                }
              }
            }
          },
          429: {
            description: 'Quota exceeded'
          },
          400: {
            status: 400,
            message: 'Bad request'
          }
        }
      }
    },
    '/webhooks': {
      parameters: [
        {
          name: 'Authorization',
          in: 'header',
          description: 'secret key',
          example: 'sk_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          required: true
        }
      ],
      post: {
        summary: 'Create webhook subscriptions',
        description: `This API allows you to create a webhook subscription for natural disaster data.
          Data will pushed to the specified https URL once we receive it, with exponential backoff for 12 hours for non 200 response codes.
          You are limited to 2 webhook subscriptions.
          To verify the request payload we provide the header dci-signature, compare the value of this header with a sha-256 hash of the event id concatenated with the timestamp value from the dci-timestamp header signed with the webhook secret.
          The webhook payload will be delivered in the following format, a javascript code example of a verification function is also provided:

          // webhook payload

          {
            id: "21284598-0ac1-47fc-9d8a-78d834357f98",
            alertlevel: "Green",
            alertscore: 0,
            createdAt: "2020-09-01T05:19:40.000Z",
            description: "On 9/1/2020 5:19:40 AM, an earthquake occurred in Chile potentially affecting About 69000 people within 100km. The earthquake had Magnitude 4.7M, Depth:17.12km.",
            guid: "EQ1232591",
            landCountry: null,
            latlong: [-27.9293, -71.293],
            magnitudeUnit: "M",
            magnitudeValue: "4.7",
            severity: "Magnitude 4.7M, Depth:17.12km",
            title: "Green earthquake alert (Magnitude 4.7M, Depth:17.12km) in Chile 01/09/2020 05:19 UTC, About 69000 people within 100km.",
            type: "EQ",
            updatedAt: "2020-09-01T06:27:00.365Z",
            url: "http://www.gdacs.org/report.aspx?eventtype=EQ&amp;eventid=1232591"
          }
          
          // example verification function (javascript)

          const verifySignature = (event, timestamp, dciSignature) => crypto.createHmac('sha256', webhook.secret)
            .update(\`\${event.id}.\${timestamp}\`)
            .digest('base64') === dciSignature

          const isVerifiedRequest = verifySignature(req.body, req.headers['dci-timestamp'], req.headers['dci-signature']) 

        `,
        requestBody: {
          content: {
            'application/json': {
              schema: {
                type: 'object',
                properties: {
                  url: {
                    type: 'string'
                  }
                }
              }
            }
          }
        },
        responses: {
          200: {
            description: 'Successfully created webhook',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        url: {
                          type: 'string'
                        },
                        userId: {
                          type: 'string'
                        },
                        secret: {
                          type: 'string'
                        },
                        createdAt: {
                          type: 'date-time'
                        }
                      }
                    },
                    object: {
                      type: 'string'
                    }
                  }
                }
              }
            }
          },
          429: {
            description: 'Quota exceeded'
          }
        }
      },
      get: {
        summary: 'Fetch webhooks',
        description: 'This API retrieves current webhook subscriptions.',
        responses: {
          200: {
            description: 'Successfully fetch webhooks',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    data: {
                      type: 'object',
                      properties: {
                        url: {
                          type: 'string'
                        },
                        userId: {
                          type: 'string'
                        },
                        secret: {
                          type: 'string'
                        },
                        createdAt: {
                          type: 'date-time'
                        }
                      }
                    },
                    object: {
                      type: 'string'
                    }
                  }
                }
              }
            }
          },
          429: {
            description: 'Quota exceeded'
          }
        }
      }
    }
  }
}
