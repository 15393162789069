import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Typography, Button, Paper, Box, CircularProgress, List, ListItem, ListItemText
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useService } from '../../common/ServiceContext'

export const Account = () => {
  const history = useHistory()
  const { DCIService } = useService()
  const [loading, setLoading] = useState(true)
  const [subscription, setSubscription] = useState<any>({})
  useEffect(() => {
    DCIService.getSubscription()
      .then((a) => {
        setLoading(false)
        setSubscription(a.data)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])
  return (
    <Paper>
      <Box style={{ padding: 40, display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6">
          You&apos;re on the the&nbsp;
          <strong>professional plan</strong>
        </Typography>
        <Button onClick={() => { history.push('/plans') }} variant="contained" color="primary">Change plan</Button>
      </Box>
      <Box style={{
        padding: 40, display: 'flex', justifyContent: 'start', alignItems: 'center'
      }}
      >
        {loading ? <CircularProgress />
          : (
            <List>
              <ListItem>
                <ListItemText>
                  Next billing period starts&nbsp;
                  <strong>{moment.unix(subscription.current_period_end).format('LL')}</strong>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Amount to be billed&nbsp;
                  <strong>
                    £
                    {(subscription.amount / 100).toFixed(2)}
                  </strong>
                </ListItemText>
              </ListItem>
            </List>
          )}
      </Box>
    </Paper>
  )
}
