import React, { useState } from 'react'
import moment from 'moment'
import {
  Table, TableCell, TableRow, IconButton, Collapse, Box, Typography, TableHead, TableBody
} from '@material-ui/core'
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons'

const WebhookResponse = ({ row }: { row: any } = { row: {} }) => {
  const [open, setOpen] = useState(false)
  let jsonBody
  try {
    jsonBody = JSON.stringify(JSON.parse(row.body), null, 2)
  } catch (e) {
    jsonBody = row.body
  }
  return (
    <>
      <TableRow>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <div>Show response</div>
          </div>
        </TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">{moment.unix(row.timestamp).format('YY:MM:DD HH:mm:ss')}</TableCell>
        <TableCell align="right">{row.body && row.body.length > 0 ? `${row.body.substr(0, 20)}...` : row.body}</TableCell>
        <TableCell align="right">{row.eventId}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0} style={{ padding: 40 }}>
              <code>
                <pre style={{
                  overflow: 'auto',
                  width: 700,
                  padding: 10,
                  backgroundColor: 'rgb(255, 240, 233)'
                }}
                >
                  {jsonBody}
                </pre>
              </code>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const WebhookResponses = ({ data }: { data: any[] }) => (
  <Table>
    <TableRow>
      <TableCell align="left">Recent responses</TableCell>
      <TableCell align="right">status</TableCell>
      <TableCell align="right">date</TableCell>
      <TableCell align="right">response</TableCell>
      <TableCell align="right">eventId</TableCell>
    </TableRow>
    <TableBody>
      {data.map((d: any) => (<WebhookResponse row={d} />))}
    </TableBody>
  </Table>
)
