import React from 'react'
import { CircularProgress } from '@material-ui/core'

export const Loader = () => (
  <div style={{
    display: 'flex',
    padding: 50,
    alignItems: 'center',
    justifyContent: 'center'
  }}
  >
    <CircularProgress thickness={5} />
  </div>
)
