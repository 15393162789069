import React, { useState } from 'react'
import {
  Button, TextField, CircularProgress, Link
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Link as RouterLink } from 'react-router-dom'
import { useService } from '../../common/ServiceContext'
import { Loader } from '../../common/components/Loader'

export const ForgotPassword = () => {
  const { authService } = useService()
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div style={{ width: 300, textAlign: 'center', position: 'relative' }}>
        {
          loading
            ? <Loader />
            : (
              <>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  variant="outlined"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  size="small"
                  fullWidth
                />
                <Button
                  onClick={() => {
                    setLoading(true)
                    authService.forgotPassword(email)
                      .then(() => {
                        setLoading(false)
                        setSuccess(true)
                        setError('')
                      })
                      .catch((e: Error) => {
                        setLoading(false)
                        setError(e.message)
                      })
                  }}
                  style={{ marginBottom: 5 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  Send password reset email
                </Button>
              </>
            )
        }
        { success && <Alert style={{ marginTop: 5, marginBottom: 5 }} severity="success">Success! Please check your email account</Alert> }
        { error && <Alert style={{ marginTop: 5, marginBottom: 5 }} severity="error">{error}</Alert> }
        <Link component={RouterLink} to="/login" style={{ fontSize: 14 }}>Login</Link>
        <p style={{ marginTop: 20 }}>
          Dont have an account? &nbsp;&nbsp;&nbsp;
          <Link component={RouterLink} to="/signup">Create Account</Link>
        </p>
      </div>
    </>
  )
}
