import React, { useState } from 'react'
import {
  Button, TextField, Link
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useService } from '../../common/ServiceContext'
import { Loader } from '../../common/components/Loader'

export const Login = () => {
  const { authService } = useService()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const login = () => {
    setLoading(true)
    return authService.login(email, password)
      .then(() => {
        const w = window as unknown as any
        w.fcWidget.user.setEmail(email)
        setLoading(false)
        history.push('/home')
      })
      .catch((e: Error) => {
        setLoading(false)
        setError(e.message)
      })
  }
  return (
    <>
      <div style={{ width: 300, textAlign: 'center', position: 'relative' }}>
        {
          loading
            ? <Loader />
            : (
              <>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  type="email"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      login()
                    }
                  }}
                  variant="outlined"
                  style={{ marginTop: 10 }}
                  size="small"
                  fullWidth
                />
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="Password"
                  type="password"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      login()
                    }
                  }}
                  variant="outlined"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  size="small"
                  fullWidth
                />
                <Button
                  onClick={() => login()}
                  style={{ marginBottom: 5 }}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  Login
                </Button>
              </>
            )
        }
        { error && <Alert style={{ marginTop: 5, marginBottom: 5 }} severity="error">{error}</Alert> }
        <Link component={RouterLink} to="/forgot-password" style={{ fontSize: 14 }}>Forgotten password?</Link>
        <p style={{ marginTop: 20 }}>
          Dont have an account? &nbsp;&nbsp;&nbsp;
          <Link component={RouterLink} to="/signup">Create Account</Link>
        </p>
      </div>
    </>
  )
}
